<script setup lang="ts">
import { AuthView } from "#imports";

const { isAuthDialogOpen, isLoggedIn, currentUser, logoutUser, authView } =
  useAuth();

const localePath = useLocalePath();

const titleDefault = computed(() => {
  switch (authView.value) {
    case AuthView.Reset:
    case AuthView.Confirm:
    case AuthView.Register:
      return "nowe";
    default:
      return undefined;
  }
});

const titleAppend = computed(() => {
  switch (authView.value) {
    case AuthView.Login:
    case AuthView.Recover:
      return "logowanie";
    case AuthView.Reset:
      return "hasło";
    case AuthView.Confirm:
    case AuthView.Register:
      return "konto";
    default:
      return undefined;
  }
});

const title = ref("");

const onSetTitle = (value: string) => {
  title.value = value;
};

const success = ref(false);

const onSetSuccess = (value: boolean) => {
  success.value = value;
};

const { settings, getAuthViewImage } = useViewsSettings();

const viewImage = computed(() => getAuthViewImage(authView.value)?.id);
</script>

<template>
  <AppDialog
    v-model:is-open="isAuthDialogOpen"
    :title="isLoggedIn ? 'Jesteś zalogowany jako:' : title"
    wide
    front
  >
    <template #side>
      <NuxtPicture
        v-if="settings"
        :src="viewImage || '/img/auth.jpeg'"
        :provider="viewImage ? 'directus' : undefined"
        :img-attrs="{
          class: 'block absolute inset-0 w-full h-full object-cover',
          role: 'presentation',
        }"
        format="webp"
        sizes="290px xs:353px sm:576px md:692px lg:421px xl:592px xxl:720px"
        quality="75"
        role="presentation"
        alt=""
      />
    </template>

    <template #subheader>
      <div class="grow"></div>
      <PageTitle :default="titleDefault" :append="titleAppend" />
    </template>

    <template #default>
      <div v-if="isLoggedIn" class="flex flex-col items-stretch gap-4 mt-4">
        <div class="flex flex-col items-center text-center mb-4">
          <AppAvatar :user="currentUser" class="mb-2" size="lg" />
          <p class="font-semibold">
            {{ currentUser?.first_name }} {{ currentUser?.last_name }}
          </p>
          <p class="text-xs text-grey-600">{{ currentUser?.email }}</p>
        </div>

        <AppBtn block :to="localePath({ name: 'boards' })" variant="solid">
          <span>Moje tablice</span>
        </AppBtn>

        <AppBtn block :to="localePath({ name: 'account-settings' })">
          <span>Ustawienia konta</span>
        </AppBtn>

        <AppBtn block @click="logoutUser">
          <span>Wyloguj się</span>
        </AppBtn>
      </div>

      <template v-else>
        <AppAuthConfirm
          v-if="authView === AuthView.Confirm"
          v-bind="{ onSetTitle, onSetSuccess }"
        />
        <AppAuthLogin
          v-else-if="authView === AuthView.Login"
          v-bind="{ onSetTitle, onSetSuccess }"
        />
        <AppAuthRecover
          v-else-if="authView === AuthView.Recover"
          v-bind="{ onSetTitle, onSetSuccess }"
        />
        <AppAuthRegister
          v-else-if="authView === AuthView.Register"
          v-bind="{ onSetTitle, onSetSuccess }"
        />
        <AppAuthReset
          v-else-if="authView === AuthView.Reset"
          v-bind="{ onSetTitle, onSetSuccess }"
        />
      </template>
    </template>
  </AppDialog>
</template>
