<script setup lang="ts">
const emit = defineEmits<{ close: [] }>();

const { isAuthDialogOpen } = useAuth();

const onClose = () => {
  emit("close");
  isAuthDialogOpen.value = false;
};

const { currentPalette } = useTheme();
const { isMobile } = useUserDevice();

const { getAuthViewImage } = useViewsSettings();

const viewImage = computed(() => getAuthViewImage(AuthView.Recover)?.id);
</script>

<template>
  <div class="app-auth-recover-success flex flex-col items-stretch">
    <div
      class="app-auth-recover-success__wrapper grow flex flex-col items-stretch"
    >
      <div v-if="isMobile" class="grow basis-[24.5625rem] relative">
        <NuxtPicture
          :src="viewImage || '/img/contact-success.jpeg'"
          :provider="viewImage ? 'directus' : undefined"
          class="app-auth-recover-success__image block absolute inset-0 w-full h-full object-cover"
          :img-attrs="{
            class: 'block absolute inset-0 w-full h-full object-cover',
          }"
          width="353"
          height="500"
          :class="[currentPalette.bg]"
          role="presentation"
          alt=""
        />
      </div>

      <div
        v-else
        class="app-auth-recover-success__actions relative grow mt-12 flex flex-col items-stretch justify-end gap-6"
      >
        <AppBtn type="button" variant="solid" block @click="onClose">
          Zamknij
        </AppBtn>
      </div>
    </div>
  </div>
</template>
